.preloader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  min-width: 200px;
  width: 100%;
  height: 64px;
  margin: auto;
  z-index: 9999;
  background: transparent;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.6s $transition-animate, visibility 0.6s $transition-animate;

  &--hidden {
    @include fadeOut(0.6s);
  }

  &--full {
    position: fixed;
    height: 100%;
    background: $color-light;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
  }

  &__circle {
    position: relative;
    display: flex;
    height: 64px;
    width: 64px;
    border-radius: 100%;
    flex-shrink: 0;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 9px;
      bottom: 9px;
      left: 9px;
      right: 9px;
      z-index: 10;
      opacity: 1;
      background: #fff;
      border-radius: 100%;
    }

    span {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background: conic-gradient(from 180deg at 50% 50%, #1715a2 0deg, #d8caff 360deg);
      animation: loader 2s linear infinite;
    }
  }

  &__img {
    position: relative;
    width: 32px;
    height: 32px;
    margin: auto;
    z-index: 10;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    margin-left: 15px;
    color: $color-dark;
  }
}

.preloader-wrapper {
  position: relative;
  display: flex;
  z-index: 9998;
  &.local {
    z-index: 1;
    height: 64px;
  }
}

.preloader-inline {
  position: absolute;
  width: 100%;
  min-height: 64px;
  height: 100%;

  .preloader-wrapper {
    height: 100%;
    background: #fff;
  }

  .preloader {
    background: #fff;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

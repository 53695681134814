@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-Light.woff2') format('woff2'),
        url('IBMPlexSansCond-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-SemiBold.woff2') format('woff2'),
        url('IBMPlexSansCond-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-SemiBoldItalic.woff2') format('woff2'),
        url('IBMPlexSansCond-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-MediumItalic.woff2') format('woff2'),
        url('IBMPlexSansCond-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-ExtraLight.woff2') format('woff2'),
        url('IBMPlexSansCond-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-ThinItalic.woff2') format('woff2'),
        url('IBMPlexSansCond-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-Medium.woff2') format('woff2'),
        url('IBMPlexSansCond-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-BoldItalic.woff2') format('woff2'),
        url('IBMPlexSansCond-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-Bold.woff2') format('woff2'),
        url('IBMPlexSansCond-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-Regular.woff2') format('woff2'),
        url('IBMPlexSansCond-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-Thin.woff2') format('woff2'),
        url('IBMPlexSansCond-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-ExtraLightItalic.woff2') format('woff2'),
        url('IBMPlexSansCond-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-Italic.woff2') format('woff2'),
        url('IBMPlexSansCond-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('IBMPlexSansCond-LightItalic.woff2') format('woff2'),
        url('IBMPlexSansCond-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


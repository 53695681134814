@import '../settings';

.status {
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    width: 12px;
    height: 12px;
    margin-right: 9px;
    border-radius: 100%;
    flex-shrink: 0;

    @include max-768 {
      margin-right: 5px;
    }
  }

  &--new {
    span {
      background: $color-red;
    }
  }

  &--assigned {
    span {
      background: $color-orange;
    }
  }

  &--processing {
    span {
      background: #fff;
    }
  }

  &--done {
    span {
      background: $color-green;
    }
  }

  &--rejected {
    span {
      background: $color-gray3;
    }
  }
}

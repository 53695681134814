@import "../settings";

.sort {
  display: flex;
  align-items: center;
}

.sort-item {
  position: relative;
  padding-right: 16px;
  font-size: 16px;
  line-height: 1.3;

  &::after {
    position: absolute;
    bottom: 0.4em;
    opacity: 0;
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='4' viewBox='0 0 5 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.69985 3.59474C2.07875 4.13509 2.92126 4.13509 3.30015 3.59474L4.83991 1.39887C5.26046 0.799127 4.80336 0 4.03976 0H0.960236C0.196637 0 -0.26046 0.799127 0.160086 1.39887L1.69985 3.59474Z' fill='%231D00C3'/%3E%3C/svg%3E");
    width: 5px;
    height: 4px;
    margin-left: 8px;
    filter: contrast(0);
    transition: 0.2s;
  }

  &--selected {
    color: $color-primary3;

    &::after {
      filter: contrast(1);
    }
  }

  &--asc {
    cursor: pointer;

    &::after {
      opacity: 1;
    }
  }

  &--desc {
    cursor: pointer;

    &::after {
      opacity: 1;
      transform: scale(1, -1);
    }
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  @include max-576 {
    margin: -5px;
  }

  &__item {
    margin: 4px;

    @include max-576 {
      margin: 5px;
    }

    &--prev,
    &--next {
      display: none;
    }

    &.active {

      .pagination__link {
        color: $color-dark;
      }
    }

    &.disabled {

      .pagination__link {
        color: $color-dark;
        pointer-events: none;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 6px;
    font-weight: 600;
    color: $color-primary;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $color-dark;
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: -2px;
    padding: 6px;

    span {
      width: 5px;
      height: 5px;
      margin: 2px;
      border-radius: 100%;
      background: $color-gray2;
    }
  }
}

@import '../settings';

.doctype {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 13px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;

  &--pdf {
    background: #FF8E8D;
  }

  &--doc {
    background: #708EF2;
  }

  &--xls {
    background: #8CC98E;
  }

  &--img {
    color: $color-primary;
  }

  &--link {
    color: $color-primary;
  }
}

.file {
  position: relative;
  display: inline-flex;
  flex-direction: column;

  &__container {
    position: relative;
    display: flex;
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:not(:disabled) {
      & + .file__label {
        &:hover {
          background: $color-gray2;
        }
      }
    }

    &.error {
      & + .file__label {
        border-color: $color-red;
      }
    }

    &:disabled {
      & + .file__label {
        border-color: $color-gray2;
        color: $color-gray;
        pointer-events: none;
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 11px 26px;
    border: 1px solid $color-gray2;
    border-radius: 4px;
    background: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: $color-dark;
    cursor: pointer;
    transition: 0.2s;
  }

  &__icon {
    display: inline-flex;
    margin-right: 10px;
    color: $color-primary;
  }

  &__text {
  }

  &__delete {
    display: inline-flex;
    cursor: pointer;
    z-index: 10;
  }

  &__error {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.14;
    color: $color-red;
  }
}

.files-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    display: flex;
    align-items: flex-start;
    margin-top: 0.2em;
    margin-bottom: 16px;
    color: $color-primary;
  }

  &__name {
    margin-right: auto;
    word-break: break-word;

    span {
      font-size: 14px;
      color: $color-gray3;
      white-space: nowrap;
    }
  }

  &__delete {
    display: inline-flex;
    align-items: center;
    margin-left: 12px;
    font-size: 13px;
    line-height: 1;
    color: #000;
    cursor: pointer;
  }

  &__icon {
    margin-right: 10px;
    color: $color-primary;
  }

  .attachment {
    font-size: 16px;
    line-height: 1.5;
  }
}

@import '../settings';

.attachment {
  display: inline-flex;
  align-items: flex-start;
  font-size: 13px;
  line-height: 1.3;
  color: $color-gray3;
  cursor: pointer;

  &--large {
    font-size: 16px;
    line-height: 1.5;
    color: #000;

    .attachment {
      &__icon {
        width: 36px;
        height: 30px;
        margin-right: 12px;
      }

      &__name {
        margin-top: 0.2em;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    color: $color-primary;
    flex-shrink: 0;
  }

  &__name {
    word-break: break-word;

    span {
      margin-left: 0.25em;
      color: $color-gray3;
      white-space: nowrap;
    }
  }
}

@import '../settings';

.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__heading {
    font-weight: 400;
    font-size: 36px;
    line-height: 1.28;
    margin: 6px 0 0;

    @include max-576 {
      margin: 25px 0 0;
      text-align: center;
    }
  }

  &__container {
    margin-top: 24px;

    @include max-576 {
      margin-top: 16px;
    }
  }

  &__top {
    display: flex;
    align-items: center;

    &-right {
      justify-content: flex-end;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0;
    padding: 20px;
    background: #fff;
    border-radius: 6px;

    @include max-576 {
      margin: 16px 0 30px;
    }
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 400;
    line-height: 1.45;
    margin: 34px 0 0;
  }

  &__pagination {
    margin-top: 30px;

    @include max-576 {
      margin-top: 20px;
    }
  }

  &__bottom {
    margin: 30px 0;

    @include max-576 {
      margin: 16px 0 30px;
    }
  }
}

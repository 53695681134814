@import '../settings';

.form-select {

  &.react-select {

    &--is-disabled {

      .react-select {

        &__control {
          background: $color-gray2;
        }

        &__indicator {
          color: $color-gray;
        }
      }
    }
  }

  .react-select {

    &__control {
      background: #fff;
      border-radius: 6px;
      border-color: $color-gray2;
      font-size: 14px;
      line-height: 1.25;

      @include max-768 {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    &__value-container {
      padding: 11px 20px;

      @include max-768 {
        padding: 8px 20px;
      }

      &--is-multi {

        &.react-select__value-container--has-value {
          padding: 3px;
        }
      }
    }

    &__single-value {
      margin: 0;
      color: #000;
    }

    &__input-container {
      margin: 0;
      padding: 0;
      color: #000;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 0 24px 0 0;
      color: $color-primary;

      &:hover {
        color: $color-primary;
      }
    }

    &__menu {
      background-color: #fff;
      color: #000;
      margin-top: 15px;
      font-size: 14px;
      line-height: 1.25;
      z-index: 100;

      @include max-768 {
        font-size: 16px;
        line-height: 1.5;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -7px;
        left: 0;
        margin-left: 12px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 7px solid #fff;
      }

      &::before {
        top: -8px;
        margin-left: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 8px solid;
        border-bottom-color: $color-gray2;
      }

      .react-select__option--is-focused,
      .react-select__option--is-selected {
        background-color: $color-primary;
        color: #fff;
      }

      .react-select__option {
        cursor: pointer !important;
      }

      .react-select__menu-list {
        position: relative;
      }
    }

    &__multi-value {
      display: flex;
      align-items: center;
      height: 30px;
      margin: 2px;
      padding: 4px 10px;
      background: $color-light;
      border: 1px solid $color-gray2;
      border-radius: 6px;
      flex-shrink: 0;
    }

    &__multi-value__label {
      padding: 0;
      font-size: 13px;
      line-height: 1;
      color: $color-dark;

      & + div[role="button"] {
        display: flex;
      }
    }

    &__multi-value__remove {
      margin-left: 11px;
      padding: 0;
      color: $color-dark;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: inherit;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--medium {

    .react-select {

      &__control {
        font-size: 16px;
        line-height: 1.5;
      }

      &__value-container {
        padding: 10px 20px;
      }

      &__value-container {

        &--is-multi {

          &.react-select__value-container--has-value {
            padding: 5px;
          }
        }
      }

      &__menu {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  &--large {

    .react-select {

      &__value-container {
        padding: 15px 20px;
        font-size: 18px;
      }

      &__menu {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  &--top {

    .react-select__menu {
      margin-bottom: 15px;

      &::before,
      &::after {
        top: auto;
        bottom: -7px;
        transform: scale(1, -1);
      }

      &::before {
        bottom: -8px;
      }
    }
  }
}

// * BEGIN: Customize PrimeReact
.p-dropdown {
  background-color: transparent;
  border-color: #fff !important;
  color: #fff;
  width: 100%;

  &-label {
    color: #fff;
    padding: 5px 15px;
  }

  &-trigger {
    &-icon {
      color: #fff;
      font-size: 12px;
    }
  }
}

.p-dropdown-panel {
  margin-top: 12px;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -8px;
    left: 20px;

    border-bottom: 8px solid #fff;
  }

  .p-dropdown-items {
    margin: 10px 0;
  }

  .p-dropdown-item {
    padding: 10px 15px !important;
    font-size: 16px;
  }

  .p-dropdown-item.p-highlight {
    color: #fff !important;
    background: $color-primary3 !important;
  }
}
// * END: Customize PrimeReact

@import '../settings';

.alert {
  background: rgba($color-danger, .8);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  display: none;

  &--show {
    display: block;
  }
}

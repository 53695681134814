@import '../settings';

.tabs {
  display: flex;
  flex-wrap: wrap;

  &__link {
    min-width: 242px;
    border-radius: 0;

    @include max-576 {
      min-width: 100%;
    }

    &:first-of-type {
      border-radius: 6px 0 0 6px;

      @include max-576 {
        border-radius: 6px 6px 0 0;
      }
    }

    &:last-of-type {
      border-radius: 0 6px 6px 0;

      @include max-576 {
        border-radius: 0 0 6px 6px;
      }
    }
  }

  .icon-alert {
    padding: 2px 6px;
    border-radius: 10px;
    width: max-content;
  }
}

.tab-pane {
  display: none;

  &.active {
    display: block;
  }

  &.fade {
    transition: opacity .15s linear;

    &:not(.show) {
      opacity: 0;
    }
  }
}

.tabs-container {
  margin-top: 30px;
}

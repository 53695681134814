@import '../settings';

.divider {
  margin: 15px 0;
  text-align: center;
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;

  &::before,  &::after {
    content: '';
    position: relative;
    top: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-gray2;
    flex: 50%;
  }

  &__text {
    display: block;
    text-align: center;
    width: 100%;
    flex: 50px;
    text-transform: lowercase;
  }
}

@import "../settings";

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item {
    display: flex;
    margin: 50px 0 0;
  }

  &__flex {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include max-576 {
      flex-direction: column;
    }
  }

  &__btn {
    min-width: 211px;
    margin: 10px 0;

    @include max-576 {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 400;
    line-height: 1.45;
    margin: 0;

    @include max-576 {
      text-align: center;
    }
  }
}

.section-helper {
  display: flex;
  width: 100%;
  margin-top: 10px;
  color: $color-gray3;

  @include max-576 {
    margin-top: 15px;
  }

  &__text {
    margin: 0.25em 0 0 6px;
    font-size: 12px;

    @include max-576 {
      margin-top: 0;
    }
  }
}

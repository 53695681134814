@import '../settings';

.color-picker {
  min-height: 42px;
  margin-top: 0.5em;

  .swatch {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid $color-gray2;
  }

  .react-colorful {
    margin-top: 12px;
  }
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Light.woff2') format('woff2'),
        url('IBMPlexMono-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-ExtraLightItalic.woff2') format('woff2'),
        url('IBMPlexMono-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Bold.woff2') format('woff2'),
        url('IBMPlexMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Regular.woff2') format('woff2'),
        url('IBMPlexMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-ExtraLight.woff2') format('woff2'),
        url('IBMPlexMono-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Italic.woff2') format('woff2'),
        url('IBMPlexMono-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-SemiBold.woff2') format('woff2'),
        url('IBMPlexMono-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-MediumItalic.woff2') format('woff2'),
        url('IBMPlexMono-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-SemiBoldItalic.woff2') format('woff2'),
        url('IBMPlexMono-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-BoldItalic.woff2') format('woff2'),
        url('IBMPlexMono-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-LightItalic.woff2') format('woff2'),
        url('IBMPlexMono-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Medium.woff2') format('woff2'),
        url('IBMPlexMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Thin.woff2') format('woff2'),
        url('IBMPlexMono-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-ThinItalic.woff2') format('woff2'),
        url('IBMPlexMono-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}


@import '../settings';
.toaster {
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem!important;
  max-width: 100%;
  pointer-events: none;
  width: max-content;
  z-index: 1090;
  .toast {
    border-radius: 0.25rem;
    font-size: .875rem;
    max-width: 100%;
    pointer-events: auto;
    width: 350px;
    align-items: center;
    border: 0;
    transition: opacity .15s linear;
    margin-bottom: 0.75rem;
    &.bg-success {
      background: $color-success;
    }
    &.bg-danger {
      background: $color-danger;
    }
    &.text-white {
      color: $color-light
    }

    .d-flex {
      display: flex;
      .toast-body {
        word-wrap: break-word;
        padding: 0.75rem;
      }
      .btn-close {
        border: 0;
        border-radius: 0.25rem;
        box-sizing: content-box;
        height: 1em;
        opacity: .5;
        padding: 0.25em;
        width: 1em;
        margin: auto 0.5rem auto auto !important;
        background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(44, 56, 74, 0.95)'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
        &.btn-close-white {
          color: $color-light
        }
      }
    }
  }
}

@import '../settings';

.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 50px 40px 50px 47px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 74px rgba(0, 0, 0, 0.25);

  @include max-576 {
    padding: 30px;
  }

  &__text {
    font-size: 18px;
    line-height: 1.56;

    @include max-576 {
      font-size: 16px;
    }
  }

  &__btn {
    min-width: 166px;
    margin-left: auto;

    @include max-768 {
      margin-top: 1em;
    }

    @include max-576 {
      margin: 1em auto 0;
    }
  }
}

$thumb-color: #fff;
$thumb-radius: 100%;
$thumb-height: 20px;
$thumb-width: 20px;
$thumb-border-width: 2px;
$thumb-border-color: $color-primary;

$track-color: $color-primary;
$track-width: 100%;
$track-height: 2px;
$track-radius: 6px;

$contrast: 5%;

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
}

@mixin thumb {
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}

[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  margin: calc($thumb-height / 2) 0;
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    background: $track-color;
    border: 0;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: -(calc($thumb-height / 2));
  }

  &::-moz-range-track {
    @include track;
    background: $track-color;
    border: 0;
    border-radius: $track-radius;
    height: calc($track-height / 2);
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: darken($track-color, $contrast);
    border: 0;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    background: $track-color;
    border: 0;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}

@import '../assets/fonts/MoscowUniversity/stylesheet';
@import '../assets/fonts/IBMPlex/Sans/stylesheet';
@import '../assets/fonts/IBMPlex/Mono/stylesheet';
@import '../assets/fonts/IBMPlex/Condensed/stylesheet';
@import './settings';

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  background: $color-light;
  font-family: $font-primary;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: $color-dark;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

a {
  font-size: inherit;
  text-decoration: none;
  color: $color-primary;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  transition: 0.2s;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

.link {
  display: inline-flex;
  align-items: center;
  color: $color-primary;
  cursor: pointer;

  &__icon {
    margin-right: 7px;
  }
}

textarea {
  resize: none;
  outline: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  font-family: $font-secondary;
  font-weight: 400;
  line-height: 1.28;
  color: $color-dark;
}

h1,
.h1 {
  font-size: 46px;

  @include max-768 {
    font-size: 40px;
  }

  @include max-576 {
    font-size: 36px;
  }
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 30px;
  margin-bottom: 10px;

  @include max-768 {
    font-size: 26px;
  }

  @include max-576 {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-size: 22px;
  line-height: 1.45;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;

  & > li {
    list-style: none;
  }
}

input {
  font-family: $font-primary;
  font-weight: 400;
  outline: none;

  &:disabled {
    color: inherit;
    background-color: #dbdee7;
  }
  &.custom-disabled:disabled {
    color: inherit;
    background: #dbdee7;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

button {
  font-family: $font-primary;
  font-weight: 400;
  outline: none;
  background: transparent;
  box-shadow: none;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.app {
  display: flex;

  &__content {
    position: relative;
    width: calc(100% - 313px);
    min-height: 80vh;
    padding: 20px 0 50px;

    @include max-991 {
      width: 100%;
      padding: 100px 0 40px;
    }

    @include max-768 {
      padding: 60px 0 30px;
    }
  }
}

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  opacity: 0;

  &--visible {
    opacity: 1;
  }
}

.container {
  max-width: 1200px;
  width: calc(100% - 40px);
  margin: 0 auto;

  @include max-1440 {
    max-width: 950px;
  }

  @include max-576 {
    width: 100%;
    padding: 0 20px;
  }
}

.card {
  background: #fff;
  border-radius: 6px;
  padding: 16px 30px;
  width: 100%;
  margin: 10px 15px;

  @include max-576 {
    padding: 11px 20px;
    margin: 5px 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &--full {
    padding: 40px 0;
  }

  &--single {
    margin: 10px 0;
  }
}

.avatar-editor {
  margin-top: 40px;

  .form-control {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  .form-label {
    display: inline-flex;
    margin-right: 12px;
    color: $color-primary;
  }

  .form-range {
    width: 200px;
  }
}

input[type='time'] {
  &::-webkit-calendar-picker-indicator {
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11.5858C11 12.1162 11.2107 12.6249 11.5858 13L13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L13 11.5858V7Z' fill='%235324FF'/%3E%3C/svg%3E");
  }
}

.no-wrap {
  white-space: nowrap;
}

// BEGIN: Libs
@import './libs/breadcrumbs';
@import './libs/burger';
@import './libs/color-picker';
@import './libs/divider';
@import './libs/doctype';
@import './libs/dropdown';
@import './libs/dropdown-tree';
@import './libs/file';
@import './libs/filter';
@import './libs/form';
@import './libs/input-range';
@import './libs/page';
@import './libs/pagination';
@import './libs/preloader';
@import './libs/section';
@import './libs/sort';
@import './libs/table';
@import './libs/toasts';
// END: Libs

// BEGIN: UI
@import './ui/alert';
@import './ui/attachment';
@import './ui/button';
@import './ui/checkbox';
@import './ui/cookie';
@import './ui/datepicker';
@import './ui/input';
@import './ui/modal';
@import './ui/radio';
@import './ui/select';
@import './ui/status';
@import './ui/tabs';
@import './ui/tags';
@import './ui/tooltip';
@import './ui/validation';
@import './ui/app-table-loader';
// END: UI

// BEGIN: Classes
@import 'classes';
// END: Classes

// coreui - chartjs
@import '@coreui/chartjs/scss/coreui-chartjs';

@import './settings';

.overflow-hidden {
  overflow: hidden;
}

.md {
  display: none;

  &--1 {
    @include max-991 {
      display: block;
    }
  }

  &--2 {
    @include max-768 {
      display: block;
    }
  }

  &--3 {
    @include max-576 {
      display: block;
    }
  }
}

.text-alert {
  color: $color-danger;
}

.icon-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 9px;
  background: $color-danger;
  border-radius: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  flex-shrink: 0;
}

.break-word {
  word-break: break-all;
}

.status-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 9px;
  border: 1px solid $color-gray3;
  border-radius: 18px;
  font-size: 13px;
  line-height: 1;
  color: $color-gray3;

  &--active {
    background: $color-green1;
    border-color: $color-green1;
    color: #fff;
  }

  &--inactive {
    background: $color-palered1;
    border-color: $color-palered1;
    color: #fff;
  }
}

.mobile-header {
  display: none;

  @include max-768 {
    display: inline;
    margin-right: 0.25em;
  }

  &--block {
    @include max-768 {
      display: block;
      width: 100%;
    }
  }
}

.mobile-hidden {
  @include max-576 {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.color-danger {
  color: $color-danger;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

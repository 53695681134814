@import '../settings';

.is-invalid {
  border-color: $color-danger;
  .react-select__control {
    border-color: $color-danger;
  }
}

.is-valid {
  border-color: $color-success;
  .react-select__control {
    border-color: $color-success;
  }
}

.invalid-feedback {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  color: $color-danger;
}

.warning-feedback {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  color: $color-orange;
}

.valid-feedback {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  color: $color-success;
}

@import '../settings';

.dropdown {
  position: relative;

  &--opened {

    .dropdown__body {
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }

  &__body {
    position: absolute;
    background-color: #fff;
    top: calc(100% + 15px);
    right: -30px;
    min-width: 237px;
    border-radius: 6px;
    z-index: 9;
    filter: drop-shadow(4px 4px 34px rgba(0, 0, 0, 0.15));
    opacity: 0;
    visibility: hidden;
    height: 0;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      right: 36px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
    }
  }

  &__content {
    position: relative;
  }

  &__link {
    color: #000;
    transition: all 0.4s $transition-animate;
  }

  &__items {
    display: flex;
    padding: 14px 30px;
    white-space: nowrap;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.4s $transition-animate;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &:hover {
      background-color: $color-primary;
      color: #fff;

      .dropdown__icons {
        color: #fff;
      }
      .dropdown__link {
        color: #fff;
      }
    }
  }

  &__icons {
    color: $color-primary;
    margin-right: 5px;
    transition: all 0.4s $transition-animate;
  }
}

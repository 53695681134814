@import '../settings';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  max-width: 950px;
  width: 90%;
  max-height: 90%;
  z-index: 1000;
  outline: none;

  @include max-576 {
    width: 100%;
    max-height: 100%;
  }

  &__container {
    width: 100%;
    padding: 42px 98px 60px;
    border-radius: 6px;
    background: #fff;
    overflow: hidden auto;
    scrollbar-width: thin;

    @include max-991 {
      padding: 40px;
    }

    @include max-768 {
      padding: 60px 20px;
    }

    @include max-576 {
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: $color-gray2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: $color-gray;

      &:hover {
        background: $color-primary-hover;
      }
    }
  }

  &__head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 42px;
    background: #fff;
    border-radius: 6px 6px 0 0;
    z-index: 1000;

    @include max-991 {
      height: 40px;
    }

    @include max-768 {
      height: 60px;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 100%;
    color: $color-dark;

    @include max-576 {
      top: 10px;
      right: 10px;
    }
  }

  .table-container {
    width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.modal-heading {
  margin-bottom: 35px;
  font-family: $font-secondary;
  font-size: 22px;
  line-height: 1.45;
}

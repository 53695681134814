@import '../settings';

.form-input {
  width: 100%;
  border: 1px solid $color-gray2;
  padding: 11px 20px;
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.25;
  transition: 0.2s;

  @include max-768 {
    padding: 8px 20px;
    font-size: 16px;
    line-height: 1.5;
  }

  &::placeholder {
    color: $color-gray3;
  }

  &--medium {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 1.5;
  }

  &--large {
    padding: 15px 20px;
    font-size: 18px;
  }

  &--withIcon {
    padding-right: 54px;

    &:not(:placeholder-shown) {

      & + .form-icon {

        .form-icon__icon {

          &--search {
            opacity: 0;
            z-index: -1;
          }

          &--clear {
            opacity: 1;
            z-index: 1;
          }
        }
      }
    }
  }

  &--invalid {
    border-color: $color-danger;
  }
}

.input-dropdown {
  position: relative;
  width: 100%;

  &--large {

    .input-dropdown {

      &__field {
        padding: 15px 20px;
      }

      &__input {
        font-size: 18px;
      }

      &__content {
        top: calc(100% + 16px)
      }
    }
  }

  &--opened {

    .input-dropdown {

      &__icon--toggle {
        transform: scale(1, -1);
      }

      &__content {
        display: block;
      }
    }
  }

  &--accordion {

    &.input-dropdown--opened {

      .input-dropdown {

        &__content {
          max-height: 442px;
          transition: max-height 0.4s ease-in-out;

          @include max-576 {
            max-height: 463px;
          }
        }
      }
    }

    .input-dropdown {

      &__content {
        position: static;
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s $transition-animate;

        .folders {
          margin-top: 20px;
        }
      }
    }
  }

  &__field {
    display: flex;
    align-items: center;
    padding: 8px 15px 8px 20px;
    background: #fff;
    border: 1px solid $color-gray2;
    border-radius: 6px;

    &--medium {
      padding: 11px 15px 11px 20px;
    }

    &--filled {

      .input-dropdown__icon {

        &--search {
          opacity: 0;
          z-index: -1;
        }

        &--clear {
          opacity: 1;
          z-index: 1;
        }
      }
    }

    &--withItems {
      position: relative;
      padding: 5px 15px 5px 20px;
      overflow: hidden;

      @include max-576 {
        padding: 5px 4px;
      }

      &.input-dropdown__field--scrollRight {

        &::after {
          opacity: 1;
        }
      }

      &.input-dropdown__field--scrollLeft {

        &::before {
          opacity: 1;
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 72px;
        z-index: 1;
        opacity: 0;
        transition: 0.2s;
      }

      &::before {
        left: 0;
        background: linear-gradient(-270deg, #FFFFFF 32.88%, rgba(255, 255, 255, 0) 100%);
      }

      &::after {
        right: 15px;
        background: linear-gradient(270deg, #FFFFFF 67.12%, rgba(255, 255, 255, 0) 100%);
      }

      .input-dropdown__input {
        display: none;
      }
    }
  }

  &__input {
    width: 100%;
    padding: 0;
    border: 0;
    background: transparent;
    font-size: 16px;
    line-height: 1.5;
    color: $color-dark;

    &::placeholder {
      color: $color-gray3;
    }
  }

  &__icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 1em;
    flex-shrink: 0;
    z-index: 10;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-primary;
    transition: 0.2s;
    cursor: pointer;
    z-index: 1;

    &--clear {
      opacity: 0;
      z-index: -1;
    }

    &--toggle {
      position: static;
      transform: none;
    }
  }

  &__content {
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    z-index: 100;
  }

  &__items {
    position: relative;
    display: flex;
    width: 100%;
    overflow: auto hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.input-dropdown-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 30px;
  padding: 4px 10px;
  background: $color-light;
  border: 1px solid $color-gray2;
  border-radius: 6px;
  font-size: 13px;
  line-height: 1;
  color: $color-dark;

  &:not(:last-of-type) {
    margin-right: 4px;
  }

  &__icon {
    margin-left: 11px;
    cursor: pointer;
  }
}

.filter {
  margin-top: 18px;

  &--open {

    .filter {

      &__icon {
        transform: scale(1, -1);
      }

      &__body {
        max-height: 1500px;
        overflow: visible;
        opacity: 1;
        transition: 0.8s ease-in-out;
      }
    }
  }

  &__head {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .page__title {
      margin: 0;
    }
  }

  &__icon {
    margin-left: 5px;
    color: $color-primary;
    transition: 0.2s;

    @include max-576 {
      margin-left: 16px;
    }
  }

  &__body {
    max-height: 0;
    display: flex;
    overflow: hidden;
    opacity: 0;
    transition: 0.4s $transition-animate;
  }

  &__inner {
    width: 100%;
    margin-top: 10px;
    padding: 30px 30px 50px;
    background: #fff;
    border-radius: 6px;

    @include max-576 {
      margin-top: 0;
      padding: 30px 20px 50px;
    }

    .filter {

      &__form {
        margin: 5px -15px -10px;

        @include max-576 {
          margin: -10px 0;
        }
      }

      &__group {
        margin: auto 15px 10px;

        @include max-576 {
          margin: 10px 0;
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin: 10px -15px -10px;

    @include max-576 {
      width: 100%;
      margin: 0 0 -10px;
    }

    &--inline {
      align-items: flex-end;

      .form-col {

        &:not(.form-col--buttons) {
          width: auto;
          flex-grow: 1;

          @include max-576 {
            width: 100%;
          }
        }
      }
    }

    .form-col {
      margin-top: auto;
    }
  }
}

.filter-status {
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding: 16px 30px;
  background: #fff;
  border-radius: 6px;

  @include max-576 {
    flex-direction: column;
  }

  &__label {
    margin: 0.5em 35px 0 0;
    color: $color-gray3;
    flex-shrink: 0;

    @include max-576 {
      margin: 0 0 8px;
    }

    span {
      color: $color-danger;
    }
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin: -8px;

    @include max-576 {
      flex-direction: column;
    }

    & > * {
      margin: 8px;
    }
  }

  .form-select {
    flex-grow: 1;
  }
}

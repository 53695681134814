@import '../settings';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 11px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: $color-dark;
  cursor: pointer;
  transition: all 0.4s $transition-animate;

  &:hover {
    background: $color-gray-hover;
  }

  &:disabled {
    background: $color-primary-disabled;
    border-color: $color-primary-disabled;
    color: #fff;
    pointer-events: none;
  }

  &--small {
    padding: 8px 20px;
  }

  &--large {
    padding: 14px 34px;
    font-size: 20px;
  }

  &--outlined {
    background: transparent;
    border-color: $color-gray2;

    &:disabled {
      background: transparent;
      border-color: $color-gray2;
      color: $color-gray;
    }
  }

  &--filled {
    background: $color-primary;
    border-color: $color-primary;
    color: #fff;

    &:hover,
    &:focus {
      border-color: $color-primary-hover;
      background: $color-primary-hover;
    }
  }

  &--white {
    background: #fff;
  }

  &--action {
    padding: 0;
    border: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.3;
    white-space: nowrap;

    &:hover,
    &:focus {
      background: transparent;
    }

    &:disabled {
      background: none;
      color: $color-primary-disabled;
      pointer-events: none;
      .btn__icon {
        color: $color-primary-disabled;
      }
    }

    .btn__icon {
      &--left {
        margin-right: 10px;
      }

      &--right {
        margin-left: 10px;
      }
    }
  }

  &--w362 {
    min-width: 362px;

    @include max-576 {
      min-width: 100%;
    }
  }

  &__icon {
    color: $color-primary;

    &--left {
      margin-right: 20px;
    }

    &--right {
      margin-left: 20px;
    }
  }
  &__custom {
    position: relative;
  }
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin: 35px -10px -10px;

  .btn {
    min-width: 166px;
    margin: 10px;

    @include max-400 {
      width: 100%;
    }
  }
}

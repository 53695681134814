@import '../settings';
@import 'react-dropdown-tree-select/dist/styles.css';

.dropdown-tree {
  width: 100%;
}

.react-dropdown-tree-select {

  .dropdown {
    display: block;

    .dropdown-trigger {
      display: inline-flex;
      width: 100%;
      padding: 8px 20px;
      border: 1px solid $color-gray2;
      border-radius: 6px;
      line-height: 1.5;

      &.arrow.bottom:after,
      &.arrow.top:after {
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.90274 7.91544C3.54772 7.56042 3.54772 6.98481 3.90274 6.62979C4.25776 6.27477 4.83337 6.27477 5.18839 6.62979L10.0001 11.4415L14.8118 6.62979C15.1668 6.27477 15.7425 6.27477 16.0975 6.62979C16.4525 6.98482 16.4525 7.56042 16.0975 7.91544L10.6429 13.37C10.2879 13.725 9.71231 13.725 9.35728 13.37L3.90274 7.91544Z' fill='%235324ff'/%3E%3C/svg%3E");
        margin: auto 0 auto auto;
        flex-shrink: 0;
      }

      &.arrow.top:after {
        transform: scale(1, -1);
      }
    }

    .dropdown-content {
      top: calc(100% + 20px);
      left: 0;
      right: 0;
      padding: 10px 5px 12px 20px;
      border: 1px solid $color-gray2;
      border-radius: 6px;
      box-shadow: none;

      .infinite-scroll-component {

        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background: $color-gray2;
        }
      }
    }
  }
}

.tag-item .search {
  border: 0;
  font-size: 16px;
}

.node {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: $color-primary;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &.focused {
    background-color: inherit;
  }

  &.dir,
  &.link,
  &.file {
    flex-direction: row;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 9px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      flex-shrink: 0;
    }
  }

  &.dir {

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='34' height='30' viewBox='0 0 34 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 25L1.5 29H33V4.5H13L10 1L3.5 0.5L0.5 2V25Z' fill='%23DBDEE7'/%3E%3Cpath d='M31.2906 3.33333C31.2906 3.33333 14.8538 3.33333 13.5469 3.33333C13.0475 3.33333 12.41 2.30208 11.5813 1.35417C10.8269 0.489583 10.0194 0 9.40312 0C8.60625 0 2.99625 0 2.99625 0C1.34937 0 0 0.927084 0 2.53125V26.9687C0 28.5833 1.34937 30 2.99625 30H31.2906C32.9375 30 34 28.5833 34 26.9687V5.91667C34 4.30208 32.9375 3.33333 31.2906 3.33333ZM2.99625 1.66667H7.65C7.65 1.66667 8.43625 1.66667 8.85063 1.66667C9.265 1.66667 9.76438 1.83333 10.37 2.53125C11.6556 4.02083 12.0169 5 13.5469 5H31.2906C31.9919 5 32.3 5.22917 32.3 5.91667V8.33333H1.7V2.53125C1.7 1.59375 2.79437 1.66667 2.99625 1.66667ZM31.2906 28.3333H2.99625C2.30562 28.3333 1.7 27.6875 1.7 26.9687V10H32.3V26.9687C32.3 27.5937 32.0238 28.3333 31.2906 28.3333Z' fill='%235324FF'/%3E%3C/svg%3E");
    }
  }

  &.link {

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.2359 3.76339C17.8997 1.42818 14.1176 1.41 11.7591 3.72264L9.182 6.24959C8.78852 6.6354 8.78244 7.26701 9.16842 7.66032C9.5544 8.05364 10.1863 8.05971 10.5797 7.67389L13.1569 5.14695C14.7338 3.6007 17.2625 3.61285 18.8245 5.17419C20.3923 6.74133 20.3979 9.28044 18.837 10.8544L16.3141 13.3985C15.9261 13.7898 15.9289 14.4214 16.3203 14.8093C16.7118 15.1972 17.3437 15.1944 17.7317 14.8031L20.2546 12.259C22.5891 9.90487 22.5807 6.10727 20.2359 3.76339ZM7.5728 10.8001C7.96267 10.4106 7.96286 9.77901 7.57324 9.3893C7.18362 8.9996 6.55172 8.9994 6.16185 9.38887L3.75262 11.7956C1.41631 14.1295 1.41572 17.9148 3.7513 20.2494C6.09162 22.5888 9.88788 22.5827 12.2207 20.236L14.5064 17.9367C14.8949 17.5459 14.8929 16.9143 14.5019 16.5259C14.1109 16.1376 13.479 16.1396 13.0905 16.5304L10.8049 18.8297C9.25077 20.393 6.72177 20.3971 5.16269 18.8386C3.60677 17.2833 3.60716 14.7616 5.16357 13.2068L7.5728 10.8001ZM14.9548 10.4047C15.3445 10.0151 15.3445 9.38345 14.9548 8.99387C14.565 8.60429 13.9331 8.60428 13.5434 8.99387L8.83362 13.7017C8.44387 14.0912 8.44387 14.7229 8.83361 15.1125C9.22336 15.502 9.85526 15.502 10.245 15.1125L14.9548 10.4047Z' fill='%235324ff'/%3E%3C/svg%3E");
      margin-top: 2px;
    }
  }

  &.file {

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H6ZM6 4L18 4V20H6V4ZM9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7H9ZM8 12C8 11.4477 8.44772 11 9 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H9C8.44772 13 8 12.5523 8 12ZM9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z' fill='%235324ff'/%3E%3C/svg%3E");
      margin-top: 2px;
    }
  }

  & > label {
    margin: 0;
    white-space: break-spaces;
    word-break: break-word;
  }

  &:not(.dir) .toggle,
  &.dir-empty .toggle {
    display: none;
  }

  .toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin: 2px 4px 0 0;
    border: 1px solid $color-gray2;
    border-radius: 1px;
    flex-shrink: 0;
    order: -1;
    font-style: normal;
    line-height: 17px;
  }

  input {
    display: none;
  }
}

.tag-item {
  margin: 0;

  &:not(:last-of-type) {
    margin-right: 4px;
  }
}

.tag {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 30px;
  padding: 4px 10px;
  background: $color-light;
  border: 1px solid $color-gray2;
  border-radius: 6px;
  font-size: 13px;
  line-height: 1;
  color: $color-dark;
}

.tag-remove {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 11px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3313 14.4876C13.6508 14.8071 14.1688 14.8071 14.4883 14.4876C14.8078 14.1681 14.8078 13.65 14.4883 13.3305L10.1578 8.99999L14.4883 4.66944C14.8078 4.34992 14.8078 3.83187 14.4883 3.51235C14.1688 3.19283 13.6508 3.19283 13.3313 3.51235L9.00072 7.8429L4.67016 3.51235C4.35064 3.19282 3.8326 3.19282 3.51308 3.51235C3.19356 3.83186 3.19356 4.34991 3.51308 4.66943L7.84364 8.99999L3.51308 13.3305C3.19356 13.65 3.19356 14.1681 3.51308 14.4876C3.8326 14.8072 4.35064 14.8072 4.67016 14.4876L9.00072 10.1571L13.3313 14.4876Z' fill='%230B0026'/%3E%3C/svg%3E");
}

.appeal-form__col .node.link {
  margin-top: 0;
  font-size: 16px;
  line-height: 1.5;
}

@import '../settings';
@import 'react-datepicker/dist/react-datepicker';

$date-color: #3a3a3a;
$date-color-light: #979797;

.datepicker {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px 8px 20px;
  background: #fff;
  border: 1px solid $color-gray2;
  border-radius: 6px;

  &--medium {
    padding: 11px 20px;
  }

  &--disabled {
    background: $color-gray2;
  }

  &--hidden {
    display: none;
  }

  input {
    width: 100%;
    padding: 0;
    border: 0;
    background: transparent;
    font-size: 14px;
    line-height: 1.25;

    @include max-768 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

.hidden-datepicker {
  min-width: 180px;
  user-select: none;

  .react-datepicker-wrapper {
    display: flex;
  }
}

.react-datepicker {
  width: 100%;
  font-family: $font-primary;
  font-size: 12px;
  color: #000;
  border: 1px solid $color-gray2;
  border-radius: 4px;
  box-shadow: 0 6px 34px rgba(0, 0, 0, 0.1);

  &__header {
    padding: 0;
    background: #fff;
    border: 0;
    border-radius: 0 !important;
  }

  &__header-inner {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 11px;
    border-bottom: 1px solid $color-gray2;
  }

  &__nav-button {
    width: 20px;
    height: 20px;
    color: $color-primary;
    cursor: pointer;

    &:disabled {
      display: none;
    }
  }

  &__current-month {
    display: flex;
    margin: 0 auto;
    padding: 0 6px;
    font-weight: 600;
    font-size: 16px;
    color: $date-color;

    &:first-letter {
      text-transform: uppercase;
    }

    .form-select {
      margin: 0 3px;

      &:first-of-type {
        min-width: 100px;
      }

      .react-select {
        &__control,
        &__option {
          font-weight: 400;
        }

        &__value-container {
          padding: 0 6px;
        }

        &__single-value {
          overflow: unset;
        }

        &__indicator {
          padding: 0 6px 0 0;
        }
      }
    }
  }

  &__day-names {
    margin: 6px 7px -7px;
  }

  &__day,
  &__day-name {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 7 - 2px);
    aspect-ratio: 1 / 1;
    margin: 1px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: $date-color-light;

    &--disabled {
      color: #979797 !important;

      &:hover {
        color: #fff !important;
      }
    }
  }

  &__month {
    margin: 7px;

    &-container {
      float: none;
    }
  }

  &__day {
    color: $date-color;
    outline: none;

    &:hover,
    &--selected,
    &--keyboard-selected {
      border-radius: 2px;
      color: #fff;
      background: $color-primary;
    }

    &--selected {
      &:hover {
        background: $color-primary;
      }
    }

    &--today {
      font-weight: 400;
    }

    &--outside-month {
      background: transparent !important;
      color: $date-color-light !important;

      &:hover,
      &.react-datepicker__day--selected {
        background: $color-primary !important;
        color: #fff !important;
      }
    }

    &--active {
      background: $color-primary;
      color: #fff;

      &:hover {
        background: $color-primary;
      }
    }

    &--reserved {
      background: $color-gray2;
      color: $date-color;

      &:hover {
        background: $color-gray2;
        color: $date-color;
      }
    }

    &--holiday {
      background: $color-palered2;
      color: $date-color;

      &:hover {
        background: $color-palered2;
        color: $date-color;
      }
    }

    &--sunday {
      background: $color-danger;
      color: #fff;

      &:hover {
        background: $color-danger;
      }
    }
  }

  &__input-time-container {
    margin: 0 0 15px;

    .react-datepicker-time {
      &__caption {
        display: none;
      }

      &__input-container {
        display: flex;
        width: 100%;

        .react-datepicker-time {
          &__input {
            margin: 0 auto;

            input {
              width: 100%;
              border: 0;
              font-size: 16px;
              line-height: 1.5;
              color: $color-dark;
            }
          }

          &__input-inner {
            display: inline-flex;
            align-items: center;
          }

          &__input-field {
            display: inline-flex;
            align-items: center;
            width: 106px;
            height: 32px;
            margin: 0 auto;
            padding: 0 10px;
            border: 1px solid $color-gray2;
            border-radius: 6px;
          }

          &__btn {
            min-width: auto !important;
            height: 32px;
            padding: 5px 10px;
            margin-left: 1em;
            font-size: 13px;
          }
        }
      }

      &__icon {
        color: $color-primary;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 100;
}

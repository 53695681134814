.burger {
  position: relative;
  width: 34px;
  height: 100%;
  flex-shrink: 0;
  cursor: pointer;

  @include max-576 {
    width: 20px;
  }

  &__item {
    width: 100%;
    height: 2px;
    background-color: $color-light;
    display: block;
    margin: 6px 0;
    transition: all 0.4s $transition-animate;

    @include max-576 {
      margin: 5px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2) {
      transition: visibility 0.4s $transition-animate, opacity 0.4s $transition-animate;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--active {
    & > span {
      margin: 0;

      &:first-child {
        transform: rotate(45deg) translate(2px, 0px);
        margin-top: 1px;
      }

      &:nth-child(2) {
        visibility: hidden;
        opacity: 0;
        height: 0;
      }

      &:last-child {
        transform: rotate(-45deg) translate(2px, 0px);
      }
    }
  }
}

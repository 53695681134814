@import '../settings';

.table-container {
  margin-top: 40px;
  overflow: auto;
  scrollbar-width: thin;

  @include max-576 {
    margin: 30px -20px 0;
    padding: 0 20px;
  }

  &--mt-0 {
    margin-top: 0;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: $color-light;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: $color-gray2;
  }
}

.form-table {
  .table {
    thead {
      tr {
        th {
          padding-top: 0px;
        }
      }
    }
  }
}

.table {
  width: 100%;
  border-spacing: 0 3px;
  border-collapse: separate;
  font-size: 13px;
  line-height: 1.3;
  color: #000;

  &.table--wide {
    tbody {
      tr {
        td {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }

  &.table--small {
    tbody {
      tr {
        td {
          padding-top: 6px;
          padding-bottom: 6px;
          vertical-align: middle;
        }
      }
    }
  }

  thead {
    tr {
      th {
        font-weight: inherit;
        text-align: inherit;
      }

      td,
      th {
        padding: 10px;
        font-size: 16px;
        color: $color-dark;
        vertical-align: top;

        &:first-of-type {
          padding-left: 20px;
        }

        &:last-of-type {
          padding-right: 20px;
        }
      }

      &:nth-of-type(1) {
        td,
        th {
          padding-bottom: 0;
        }
      }

      &:nth-of-type(2) {
        td,
        th {
          padding-top: 0;
          padding-bottom: 15px;
          color: $color-gray3;
        }
      }
    }
  }

  tbody {
    tr {
      &.viewed {
        color: $color-gray3;

        .status {
          span {
            background: $color-gray2;
            border-color: $color-gray2;
          }
        }
      }

      &.active {
        td {
          background: #ffe1e1;
        }
      }

      &.selected {
        td {
          background: $color-gray2;
        }
      }

      td {
        padding: 14px 10px;
        background: #fff;
        vertical-align: top;

        &:first-of-type {
          padding-left: 20px;
          border-radius: 6px 0 0 6px;

          &:last-of-type {
            border-radius: 6px;
          }
        }

        &:last-of-type {
          padding-right: 20px;
          border-radius: 0 6px 6px 0;
        }

        &.table-span__col {
          vertical-align: middle;
        }
      }
    }
  }

  &__colspan {
    white-space: nowrap;
  }
}

.table-span {
  &__rows {
    div + div {
      margin-top: 20px;
    }
  }
}

.table-input {
  width: 47px;

  input {
    height: 32px;
    padding: 4px 8px;
    font-size: 14px;
    text-align: center;
  }
}

.table-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.subtable {
  width: 100%;
  padding: 0 20px;
  border-spacing: 0;
  font-size: 14px;

  tbody {
    tr {
      td {
        border-top: 1px solid $color-gray2;

        &:first-of-type {
          padding-left: 0;
          border-radius: 0;
        }

        &:last-of-type {
          padding-right: 0;
          border-radius: 0;
        }
      }
    }
  }
}

.table-accordion {
  &--opened {
    & > .table-accordion {
      &__head {
        .folders__icon {
          span {
            &:nth-of-type(2) {
              opacity: 0;
            }
          }
        }
      }

      &__body {
        max-height: 9999px;
        overflow: auto;
        transition: 0.8s ease-in-out;
      }
    }
  }

  &__head {
    padding: 4px 0 5px;
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-in-out;
  }
}

.table-grid {
  display: grid;
  gap: 4px 0;
  grid-template-columns: auto;
  margin-top: 38px;
  font-size: 13px;
  line-height: 1.3;
  overflow: auto;

  @include max-768 {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.5;
  }

  &--2cols {
    grid-template-columns: auto min-content;
  }

  &--3cols {
    grid-template-columns: min-content auto min-content;
  }

  &--4cols {
    grid-template-columns: min-content min-content auto min-content;
  }

  &--5cols {
    grid-template-columns: min-content min-content auto min-content min-content;
  }

  &--6cols {
    grid-template-columns: min-content min-content min-content auto min-content min-content;
  }

  &--7cols {
    grid-template-columns: repeat(7, auto);
  }

  &--8cols {
    grid-template-columns: repeat(8, auto);
  }

  &--medium {
    .table-grid {
      &__row {
        &:not(&--head) {
          .table-grid__cell {
            padding-top: 20px;
            padding-bottom: 20px;

            @include max-768 {
              padding-top: 0;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }

  &__row {
    display: contents;

    @include max-768 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 24px;
      border-radius: 6px;
      background: #fff;
    }

    &:not(:last-of-type) {
      @include max-768 {
        margin-bottom: 4px;
      }
    }

    &.active {
      @include max-576 {
        background: #ffe1e1;
      }

      .table-grid__cell {
        background: #ffe1e1;

        @include max-576 {
          background: transparent;
        }
      }
    }

    &--head {
      @include max-768 {
        display: none;
      }

      .table-grid__cell {
        padding-top: 6px;
        padding-bottom: 6px;
        background: transparent;
      }
    }
  }

  &__cell {
    padding: 40px 8px 30px;
    background: #fff;

    @include max-768 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 0 10px;
      background: transparent;
    }

    &:first-of-type {
      padding-left: 29px;
      border-radius: 6px 0 0 6px;

      @include max-768 {
        padding-left: 0;
        border-radius: 0;
      }
    }

    &:last-of-type {
      padding-right: 22px;
      border-radius: 0 6px 6px 0;

      @include max-768 {
        padding-right: 0;
        border-radius: 0;
      }
    }

    &--centered {
      text-align: center;
    }
  }

  .table-actions {
    @include max-768 {
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }

    .btn {
      &:not(:last-of-type) {
        @include max-768 {
          margin: 0 26px 0 0;
        }
      }
    }
  }
}

.table-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1.5em;
  font-family: $font-primary;
  font-weight: 400;
  color: $color-gray;
  padding-bottom: 15px;

  &__title {
    font-size: 22px;
    line-height: 1;
  }

  &__description {
    font-size: 14px;
  }
}

@import '../settings';

.tooltip {
  --tooltip-zindex: 1000;
  --tooltip-max-width: 200px;
  --tooltip-padding-x: 8px;
  --tooltip-padding-y: 4px;
  --tooltip-margin: 0;
  --tooltip-color: #000000;
  --tooltip-bg: #ffffff;
  --tooltip-border-radius: 4px;
  --tooltip-opacity: 0.9;
  --tooltip-arrow-width: 12px;
  --tooltip-arrow-height: 6px;

  z-index: var(--tooltip-zindex);
  display: block;
  padding: var(--tooltip-arrow-height);
  margin: var(--tooltip-margin);
  word-wrap: break-word;
  opacity: 0;
  font-size: 14px;
  line-height: 1.25;

  &.show {
    opacity: var(--tooltip-opacity);
  }

  .tooltip-arrow {
    display: block;
    position: absolute;
    width: var(--tooltip-arrow-width);
    height: var(--tooltip-arrow-height);

    &::before {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top .tooltip-arrow {
  bottom: 0;

  &::before {
    top: -1px;
    border-width: var(--tooltip-arrow-height) calc(var(--tooltip-arrow-width) * 0.5) 0;
    border-top-color: var(--tooltip-bg);
  }
}

.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: var(--tooltip-arrow-height);
  height: var(--tooltip-arrow-width);

  &::before {
    right: -1px;
    border-width: calc(var(--tooltip-arrow-width) * 0.5) var(--tooltip-arrow-height)
      calc(var(--tooltip-arrow-width) * 0.5) 0;
    border-right-color: var(--tooltip-bg);
  }
}

.bs-tooltip-bottom .tooltip-arrow {
  top: 0;

  &::before {
    bottom: -1px;
    border-width: 0 calc(var(--tooltip-arrow-width) * 0.5) var(--tooltip-arrow-height);
    border-bottom-color: var(--tooltip-bg);
  }
}

.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: var(--tooltip-arrow-height);
  height: var(--tooltip-arrow-width);

  &::before {
    left: -1px;
    border-width: calc(var(--tooltip-arrow-width) * 0.5) 0 calc(var(--tooltip-arrow-width) * 0.5)
      var(--tooltip-arrow-height);
    border-left-color: var(--tooltip-bg);
  }
}

.bs-tooltip-auto {
  &[data-popper-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[data-popper-placement^='right'] {
    @extend .bs-tooltip-end;
  }
  &[data-popper-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[data-popper-placement^='left'] {
    @extend .bs-tooltip-start;
  }
}

.tooltip-inner {
  max-width: var(--tooltip-max-width);
  padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
  color: var(--tooltip-color);
  text-align: center;
  background-color: var(--tooltip-bg);
  border-radius: var(--tooltip-border-radius, 0);
  box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);
}

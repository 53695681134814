.app-table-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  padding: 10px;
  background: #fff;
  border-radius: 6px;

  &--wrapper {
    width: 200px;
  }
}

.breadcrumbs {
  margin: 0 0 20px;

  @include max-991 {
    margin: 20px 0;
  }

  @include max-576 {
    display: none;
  }

  &__item {
    display: inline;
    word-break: break-word;
  }

  &__link {
    color: $color-dark;
}

  &__icon {
    margin: 0 6px -0.3em;
  }
}

@import '../settings';

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.tags-item {
  display: inline-flex;
  align-items: center;
  margin: 5px;
  padding: 5px 20px;
  background: #fff;
  border: 1px solid $color-gray2;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  color: $color-dark;
  transition: 0.2s;
  cursor: pointer;

  &:hover,
  &--active {
    background: $color-primary;
    border-color: $color-primary;
    color: #fff;
  }
}

@import "../settings";

.checkbox {
  position: relative;
  display: flex;

  input {
    position: absolute;
    opacity: 0;

    &:not(:disabled) {

      &:not(:checked) {

        &:not(.is-invalid) {

          &:focus {

            & + .checkbox__label {

              &::before {
                border-color: $color-primary;
                background: #fff;
              }
            }
          }
        }
      }
    }

    &:disabled {

      & + .checkbox__label {
        cursor: auto;
        color: $color-gray2;

        &::before {
          border-color: $color-gray2;
          background:  $color-gray2;
        }
      }
    }

    &:checked {

      & + .checkbox__label {

        &::before {
          background: $color-primary;
          border-color: $color-primary;
        }

        &::after {
          opacity: 1;
        }
      }

      &:disabled {

        & + .checkbox__label {

          &::before {
            border-color: transparent;
            background: $color-gray2;
          }
        }
      }

      &:focus {

        & + .checkbox__label {

          &::before {
            border-color: $color-primary;
          }
        }
      }
    }

    &.is-invalid {

      & + .checkbox__label {

        &::before {
          border-color: $color-red;
        }
      }

      &:focus {

        & + .checkbox__label {

          &::before {
            border-color: $color-red;
          }
        }
      }

      &:checked {

        & + .checkbox__label {

          &::before {
            background: $color-red;
          }
        }

        &:disabled {

          & + .checkbox__label {

            &::before {
              background: $color-red;
            }
          }
        }
      }
    }
  }

  &__label {
    position: relative;
    display: inline-flex;
    padding-top: 0.22em;
    font-size: 14px;
    line-height: 1.25;
    color: $color-dark;
    cursor: pointer;

    @include max-768 {
      padding-top: 0;
      font-size: 16px;
      line-height: 1.5;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: -0.22em 10px 0 0;
      border: 1px solid $color-gray2;
      border-radius: 6px;
      background: #fff;
      flex-shrink: 0;
      box-sizing: border-box;

      @include max-768 {
        margin-top: 0;
      }
    }

    &::after {
      opacity: 0;
      content: "";
      position: absolute;
      left: 8px;
      top: 4px;
      width: 8px;
      height: 13px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
    }
  }

  &--indeterminate {

    input:not(:checked) + .checkbox__label::before {
      background: #fff;
      border-color: $color-gray2;
    }

    input:not(:checked) + .checkbox__label::after {
      opacity: 1;
      left: 6px;
      top: 6px;
      width: 12px;
      height: 12px;
      background: $color-primary;
      border: 0;
      transform: none;
    }

    input:disabled {

      & + .checkbox__label {

        &::after {
          background:  $color-gray2;
        }
      }
    }
  }
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -10px;

  .checkbox {
    margin: 5px 10px;
  }

  &--col {
    flex-direction: column;
    margin: -9px 0;

    .checkbox {
      margin: 9px 0;
    }
  }
}

@import "media";

$color-primary: #5324ff;
$color-primary2: #14005c;
$color-primary3: #1d00c3;
$color-primary4: #220c71;
$color-primary-hover: #3818af;
$color-primary-disabled: #c0afff;
$color-success: #17c964;
$color-danger: #ff1117;
$color-light: #f5f8fd;
$color-gray: #898e93;
$color-gray-hover: #d8d8d8;
$color-gray2: #dbdee7;
$color-gray3: #8d889a;
$color-gray4: #A5A2AE;
$color-dark: #0b0026;
$color-red: #F23433;
$color-palered: #FF8F77;
$color-palered1: #DA5252;
$color-palered2: #FF8E8D;
$color-green: #7CC830;
$color-green1: #B4C83D;
$color-orange: #FF9900;
$color-yellow: #FFE600;

$color-paleblue: #D6DFFF;
$color-lightblue: #A5E9FF;
$color-lightgreen: #B9EABB;
$color-brightgreen: #D3FFA7;
$color-peach: #FFDDD6;
$color-paleyellow: #ECEDBB;


$font-primary: 'IBM Plex Sans', sans-serif;
$font-secondary: 'Moscow University', sans-serif;


$transition-animate: cubic-bezier(0.215, 0.61, 0.355, 1);

@mixin fadeOut($duration) {
  opacity: 0;
  visibility: hidden;

  animation: fadeOut 3s $transition-animate infinite;
  animation-delay: 15s;

  @keyframes fadeOut {
    to {
      display: none;
    }
  }
}

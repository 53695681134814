.form-row {
  width: calc(100% - 30px);
  margin: 10px 15px;

  @include max-576 {
    width: 100%;
    margin: 10px 0;
  }

  &--2cols {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.form-col {
  width: calc(50% - 30px);
  margin: 10px 15px;

  @include max-576 {
    width: 100%;
    margin: 10px 0;
  }

  &--buttons {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    margin: 0;

    @include max-1440 {
      flex-grow: 0;
    }

    @include max-768 {
      margin: 0;
      flex-grow: 1;
    }

    @include max-576 {
      margin: 0 -10px 10px;
    }

    .btn {
      min-width: 166px;
      width: calc(50% - 30px);
      margin: 10px 15px;

      @include max-768 {
        min-width: unset;
      }

      @include max-576 {
        width: calc(50% - 20px);
        margin: 10px;
      }
    }
  }

  &--long {
    width: calc(70% - 30px);

    @include max-576 {
      width: 100%;
    }
  }

  &--short {
    width: calc(30% - 30px);

    @include max-576 {
      width: 100%;
    }
  }
}

.form-group {
  position: relative;
  width: 100%;

  &--cols {
    display: flex;
    align-items: center;
  }

  &--checkbox {
    display: flex;
    align-items: center;
    height: 42px;

    @include max-576 {
      height: auto;
    }

    .checkbox {
      margin-right: 6px;
    }
  }

  &__label {
    display: inline-block;
    min-height: 1.5em;
    margin-bottom: 4px;
    font-family: $font-primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25;
    color: $color-gray;

    @include max-768 {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 1.5;
    }

    @include max-576 {
      margin-bottom: 4px;
    }

    span {
      color: $color-danger;
    }
  }

  &__value {
    font-size: 16px;
  }

  &__text {
    position: relative;
    font-family: $font-primary;
    font-weight: 400;
    font-size: 20px;
    display: block;
    margin: 10px 0;
    word-break: break-all;

    @include max-768 {
      font-size: 18px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-icon {
  width: 24px;
  height: 24px;
  margin-left: 1em;
  color: $color-primary;

  &--abs {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    width: 54px;
    height: 42px;
    padding: 0 20px 0 10px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $color-primary;
    transition: 0.2s;
    cursor: pointer;
    z-index: 1;

    &--clear {
      opacity: 0;
      z-index: -1;
    }
  }
}

.form-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1em;

    .form-input {
      max-width: calc(100% - 1em - 84px) !important;

      @include max-576 {
        max-width: calc(100% - 1em - 24px) !important;
      }
    }

    .btn {
      margin-left: 1em;

      .icon {
        @include max-576 {
          margin: 0;
        }
      }
    }
  }
}

.form-tags {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .form-group {
    display: flex;
    align-items: flex-start;

    @include max-576 {
      display: block;
    }
  }

  .form-group__label {
    min-width: 75px;
    margin: 0.3em 12px 0 0;
    flex-shrink: 0;

    @include max-768 {
      min-width: 85px;
    }

    @include max-576 {
      margin: 0 0 10px;
    }
  }
}
